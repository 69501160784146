import axios from 'axios';

const API_URL = 'https://cms.inboxmark.com/wp-json';
const WP_API_URL = `${API_URL}/wp/v2`;

// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

// Define types
export interface Post {
  id: number;
  slug: string;
  title: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  date: string;
  modified: string;
  featured_media: number;
  _embedded?: {
    'wp:featuredmedia'?: {
      source_url: string;
      alt_text: string;
    }[];
  };
  acf?: {
    expert_insight?: string;
    security_tip?: string;
  };
  categories: number[];
  tags: number[];
}

export interface Category {
  id: number;
  name: string;
  slug: string;
  count: number;
}

export interface Tag {
  id: number;
  name: string;
  slug: string;
  count: number;
}

export interface Media {
  id: number;
  source_url: string;
  alt_text: string;
}

// API functions
export const getPosts = async (page = 1, perPage = 10): Promise<Post[]> => {
  // Skip trying custom endpoint, go directly to standard WP API
  const response = await api.get(`${WP_API_URL}/posts`, {
    params: {
      page,
      per_page: perPage,
      _embed: 'wp:featuredmedia',
    },
  });
  return response.data;
};

export const getPost = async (slug: string): Promise<Post> => {
  // Skip trying custom endpoint, go directly to standard WP API
  const response = await api.get(`${WP_API_URL}/posts`, {
    params: {
      slug,
      _embed: 'wp:featuredmedia',
    },
  });

  if (response.data && response.data.length > 0) {
    return response.data[0];
  }

  throw new Error('Post not found');
};

export const getCategories = async (): Promise<Category[]> => {
  const response = await api.get(`${WP_API_URL}/categories`);
  return response.data;
};

export const getTags = async (): Promise<Tag[]> => {
  const response = await api.get(`${WP_API_URL}/tags`);
  return response.data;
};

export const getFeaturedMedia = async (id: number): Promise<Media> => {
  const response = await api.get(`${WP_API_URL}/media/${id}`);
  return response.data;
};

export default {
  getPosts,
  getPost,
  getCategories,
  getTags,
  getFeaturedMedia,
};