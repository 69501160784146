import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import TransitionWrapper from '../components/TransitionWrapper';
import { usePost, useCategories, useTags } from '../hooks/useWPData';
import LoadingSpinner from '../components/LoadingSpinner';
import Section from '../components/Section';
import { usePosts } from '../hooks/useWPData';
import PostCard from '../components/PostCard';
import { itemFadeIn } from '../components/animations';

// Helper function to decode HTML entities
const decodeHtmlEntities = (html: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = html;
  return textarea.value;
};

// Table of Contents component
const TableOfContents = ({ content }: { content: string }) => {
  const [headings, setHeadings] = useState<Array<{ id: string; text: string; level: number }>>([]);
  const [activeId, setActiveId] = useState<string>('');

  // Parse headings from HTML content
  useEffect(() => {
    const parsedHeadings =
      content.match(/<h[2-6]>(.*?)<\/h[2-6]>/g)?.map((heading) => {
        const level = parseInt(heading.charAt(2));
        // Decode HTML entities properly
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = heading.replace(/<\/?h[2-6]>/g, '');
        const text = decodeHtmlEntities(tempDiv.textContent || '');
        const id = text.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return { level, text, id };
      }) || [];

    setHeadings(parsedHeadings);
  }, [content]);

  // Handle scroll spy
  useEffect(() => {
    const handleScroll = () => {
      if (headings.length === 0) return;

      // Find the heading that's currently in view
      for (let i = headings.length - 1; i >= 0; i--) {
        const element = document.getElementById(headings[i].id);
        if (!element) continue;

        const rect = element.getBoundingClientRect();
        if (rect.top <= 150) {
          setActiveId(headings[i].id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [headings]);

  // Add IDs to headings when component mounts
  useEffect(() => {
    if (headings.length === 0) return;

    // Add IDs to the headings in the content
    setTimeout(() => {
      const contentDiv = document.querySelector('.prose');
      if (!contentDiv) return;

      const headingElements = contentDiv.querySelectorAll('h2, h3, h4, h5, h6');
      headingElements.forEach((el) => {
        const text = el.textContent || '';
        const id = text.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        el.id = id;
      });
    }, 100);
  }, [headings]);

  if (headings.length === 0) return null;

  return (
    <div className="sticky hidden p-4 bg-white rounded-lg shadow-lg lg:block top-24 dark:bg-secondary-800">
      <h3 className="mb-4 text-lg font-semibold text-secondary-900 dark:text-white">
        Table of Contents
      </h3>
      <nav>
        <ul className="space-y-2">
          {headings.map((heading) => (
            <li key={heading.id} className={`pl-${(heading.level - 2) * 4}`}>
              <a
                href={`#${heading.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById(heading.id);
                  if (element) {
                    // Get the element's position
                    const elementPosition = element.getBoundingClientRect().top;
                    // Get the offset position accounting for the sticky header (adjust 100px as needed)
                    const offsetPosition = elementPosition + window.pageYOffset - 100;

                    // Scroll to the element with the offset
                    window.scrollTo({
                      top: offsetPosition,
                      behavior: 'smooth',
                    });
                  }
                }}
                className={`block py-1 text-sm transition-colors duration-200 ${
                  activeId === heading.id
                    ? 'text-primary-600 font-medium dark:text-primary-400'
                    : 'text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400'
                }`}
              >
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default function Post() {
  const { slug } = useParams<{ slug: string }>();
  const { post, isLoading, error } = usePost(slug || '');
  const { categories } = useCategories();
  const { tags } = useTags();
  const { posts: relatedPosts } = usePosts(1, 3);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  // Get categories and tags for current post
  const postCategories =
    post && categories.length > 0
      ? categories.filter((cat) => post.categories.includes(cat.id))
      : [];

  const postTags = post && tags.length > 0 ? tags.filter((tag) => post.tags.includes(tag.id)) : [];

  // Format date
  const formattedDate = post ? format(new Date(post.date), 'MMMM d, yyyy') : '';

  // Filter out current post from related posts
  const filteredRelatedPosts = relatedPosts.filter((p) => p.id !== post?.id);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4 py-12">
        <h1 className="text-3xl font-bold text-secondary-900 dark:text-white">Post Not Found</h1>
        <p className="mt-4 text-xl text-secondary-600 dark:text-secondary-300">
          The article you're looking for doesn't exist or has been moved.
        </p>
        <Link
          to="/blog"
          className="inline-flex items-center px-4 py-2 mt-8 text-white bg-primary-600 rounded-md hover:bg-primary-700"
        >
          Return to Blog
        </Link>
      </div>
    );
  }

  return (
    <TransitionWrapper>
      {/* Hero Section */}
      <section className="pt-32 pb-16 bg-primary-900 md:pt-40 md:pb-16">
        <div className="container">
          <div className="max-w-3xl mx-auto text-center">
            <div className="flex justify-center space-x-3 mb-4">
              {postCategories.map((category) => (
                <Link
                  key={category.id}
                  to={`/blog?category=${category.slug}`}
                  className="px-3 py-1 text-sm font-medium text-white bg-primary-800 rounded-full hover:bg-primary-700"
                >
                  {category.name}
                </Link>
              ))}
            </div>

            <h1
              className="text-3xl font-bold tracking-tight text-white md:text-4xl lg:text-5xl font-display"
              dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(post.title.rendered) }}
            />

            <div className="flex items-center justify-center mt-6 text-primary-100">
              <time dateTime={post.date}>{formattedDate}</time>

              {post.acf?.expert_insight && (
                <span className="flex items-center ml-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                  </svg>
                  Expert Insight
                </span>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Featured Image */}
      {post._embedded?.['wp:featuredmedia']?.[0]?.source_url && (
        <div className="-mt-6 md:-mt-12">
          <div className="container">
            <div className="max-w-4xl mx-auto">
              <div className="overflow-hidden bg-white rounded-t-lg shadow-xl dark:bg-secondary-800">
                <img
                  src={post._embedded['wp:featuredmedia'][0].source_url}
                  alt={post._embedded['wp:featuredmedia'][0].alt_text || post.title.rendered}
                  className="object-cover w-full max-h-[500px]"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Post Content */}
      <Section className="pt-6 pb-16">
        <div className="container">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
            {/* Table of Contents (Sidebar) */}
            <div className="order-2 lg:order-1 lg:col-span-1">
              <TableOfContents content={post.content.rendered} />
            </div>

            {/* Main Content */}
            <div className="order-1 lg:order-2 lg:col-span-3">
              <div className="overflow-hidden bg-white rounded-lg shadow-lg dark:bg-secondary-800">
                <div className="p-6 md:p-8">
                  {/* Expert Insight Box */}
                  {post.acf?.expert_insight && (
                    <div className="p-6 mb-8 bg-primary-50 rounded-lg dark:bg-primary-900/30">
                      <h3 className="mb-3 text-lg font-semibold text-primary-900 dark:text-primary-100">
                        Expert Insight
                      </h3>
                      <p className="text-primary-800 dark:text-primary-200">
                        {post.acf.expert_insight}
                      </p>
                    </div>
                  )}

                  {/* Security Tip Box */}
                  {post.acf?.security_tip && (
                    <div className="p-6 mb-8 bg-accent-50 rounded-lg dark:bg-accent-900/30">
                      <h3 className="mb-3 text-lg font-semibold text-accent-900 dark:text-accent-100">
                        Security Tip
                      </h3>
                      <p className="text-accent-800 dark:text-accent-200">
                        {post.acf.security_tip}
                      </p>
                    </div>
                  )}

                  {/* Tool Recommendation Box */}
                  <div className="p-6 mb-8 bg-primary-50 border border-primary-100 rounded-lg dark:bg-primary-900/30 dark:border-primary-800">
                    <div className="flex items-start">
                      <div className="hidden sm:block flex-shrink-0 mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-12 h-12 text-primary-600 dark:text-primary-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={1.5}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5"
                          />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-primary-900 dark:text-primary-100">
                          Need Enterprise Email Security?
                        </h3>
                        <p className="mt-2 text-primary-800 dark:text-primary-200">
                          Implement robust email protection with Brandymail's enterprise solutions.
                          Get customizable tools for DMARC, BIMI, and VMC management.
                        </p>
                        <a
                          href="https://brandymail.com/?ref=inboxmark-article"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center mt-3 px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700"
                        >
                          Explore Enterprise Solutions
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 ml-1.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Post Content */}
                  <div
                    className="prose prose-lg max-w-none prose-headings:font-display prose-headings:font-bold prose-a:text-primary-600 prose-a:no-underline hover:prose-a:underline prose-img:rounded-lg dark:prose-invert dark:text-white"
                    dangerouslySetInnerHTML={{
                      __html: post.content.rendered.replace(
                        /<h([2-6])>(.*?)<\/h\1>/g,
                        (_, level, content) => {
                          const decodedContent = decodeHtmlEntities(content);
                          const text = decodedContent.replace(/<.*?>/g, '');
                          const id = text.toLowerCase().replace(/[^a-z0-9]+/g, '-');
                          return `<h${level} id="${id}">${decodedContent}</h${level}>`;
                        }
                      ),
                    }}
                  />

                  {/* Tags */}
                  {postTags.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-8 pt-6 border-t border-secondary-100 dark:border-secondary-700">
                      {postTags.map((tag) => (
                        <Link
                          key={tag.id}
                          to={`/blog?tag=${tag.slug}`}
                          className="px-3 py-1 text-sm text-secondary-700 bg-secondary-100 rounded-full hover:bg-secondary-200 dark:bg-secondary-700 dark:text-secondary-300 dark:hover:bg-secondary-600"
                        >
                          #{tag.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Share Buttons */}
              <div className="flex justify-center mt-8 space-x-4">
                <button className="flex items-center px-4 py-2 text-white bg-[#1877F2] rounded-md hover:bg-blue-600">
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                  </svg>
                  Share
                </button>
                <button className="flex items-center px-4 py-2 text-white bg-[#1DA1F2] rounded-md hover:bg-blue-400">
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                  Tweet
                </button>
                <button className="flex items-center px-4 py-2 text-white bg-[#0077B5] rounded-md hover:bg-blue-700">
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                  Share
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Related Posts */}
      {filteredRelatedPosts.length > 0 && (
        <div ref={ref}>
          <Section title="Related Articles" className="bg-secondary-50 dark:bg-secondary-800 py-16">
            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {filteredRelatedPosts.slice(0, 3).map((relatedPost) => (
                <motion.div
                  key={relatedPost.id}
                  variants={itemFadeIn}
                  initial="initial"
                  animate={inView ? 'animate' : 'initial'}
                >
                  <PostCard post={relatedPost} />
                </motion.div>
              ))}
            </div>
          </Section>
        </div>
      )}

      {/* CTA Section */}
      <div ref={ref}>
        <Section
          className="bg-secondary-50 dark:bg-secondary-800"
          title="Advanced Email Security"
          subtitle="Protect your organization with enterprise-grade email authentication tools."
        >
          <div className="max-w-4xl mx-auto">
            <div className="grid gap-8 md:grid-cols-2">
              <div>
                <form className="mt-4">
                  <h3 className="text-xl font-semibold text-secondary-900 dark:text-white mb-4">
                    Stay Updated
                  </h3>
                  <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="flex-1 px-4 py-2 border border-secondary-300 rounded-lg dark:border-secondary-600 dark:bg-secondary-800"
                    />
                    <button
                      type="submit"
                      className="px-6 py-2 text-white bg-primary-600 rounded-lg hover:bg-primary-700 sm:ml-4"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
              <div>
                <div className="flex items-center justify-center h-full">
                  <Link
                    to="/contact"
                    className="inline-flex items-center px-6 py-3 text-white bg-primary-600 rounded-lg hover:bg-primary-700"
                  >
                    Contact Us
                    <svg
                      className="w-5 h-5 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </TransitionWrapper>
  );
}
