import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  ShieldCheckIcon,
  EnvelopeIcon,
  LockClosedIcon,
  CheckBadgeIcon,
  ArrowRightIcon,
  ChartBarIcon,
} from '@heroicons/react/24/outline';
import TransitionWrapper from '../components/TransitionWrapper';
import Section from '../components/Section';
import { usePosts } from '../hooks/useWPData';
import PostCard from '../components/PostCard';
import LoadingSpinner from '../components/LoadingSpinner';
import { staggerContainer, itemFadeIn, slideInRight, slideInLeft } from '../components/animations';

// Stats animation
const Counter = ({ value, label, icon: Icon }: { value: number; label: string; icon: any }) => {
  const [count, setCount] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      let start = 0;
      const duration = 2000; // ms
      const step = Math.floor(duration / value);

      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start >= value) {
          clearInterval(timer);
        }
      }, step);

      return () => clearInterval(timer);
    }
  }, [inView, value]);

  return (
    <motion.div
      ref={ref}
      className="p-6 bg-white rounded-lg shadow-lg dark:bg-secondary-800"
      variants={itemFadeIn}
    >
      <div className="flex items-center mb-3">
        <Icon className="w-8 h-8 mr-3 text-primary-600 dark:text-primary-400" />
        <h3 className="text-lg font-semibold text-secondary-900 dark:text-white">{label}</h3>
      </div>
      <p className="text-3xl font-bold text-primary-700 dark:text-primary-400">
        {inView ? count : 0}%
      </p>
    </motion.div>
  );
};

const features = [
  {
    name: 'Enhanced Email Security',
    description:
      'Implement robust protocols to safeguard your organization from phishing attacks and email fraud.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Brand Protection',
    description:
      'Secure your brand identity in the digital space with Verified Mark Certificates and BIMI implementation.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'DMARC Compliance',
    description:
      'Achieve and maintain email authentication standards with expert-guided DMARC deployment.',
    icon: LockClosedIcon,
  },
  {
    name: 'Phishing Prevention',
    description:
      'Stay ahead of evolving threats with advanced phishing prevention strategies and tools.',
    icon: EnvelopeIcon,
  },
];

export default function Home() {
  const { posts, isLoading } = usePosts(1, 3);

  const stats = [
    { value: 81, label: 'Phishing Reduction', icon: ShieldCheckIcon },
    { value: 94, label: 'Brand Recognition', icon: CheckBadgeIcon },
    { value: 65, label: 'Email Deliverability', icon: EnvelopeIcon },
    { value: 78, label: 'Security Compliance', icon: ChartBarIcon },
  ];

  return (
    <TransitionWrapper>
      {/* Hero Section */}
      <section className="relative px-6 pt-32 pb-16 overflow-hidden bg-gradient-to-br from-primary-900 to-primary-800 sm:px-8 md:pt-40 md:pb-24">
        <div className="absolute inset-0">
          <svg
            className="absolute bottom-0 left-0 w-full text-white dark:text-secondary-900"
            viewBox="0 0 1440 320"
            fill="currentColor"
            preserveAspectRatio="none"
          >
            <path d="M0,224L60,229.3C120,235,240,245,360,229.3C480,213,600,171,720,170.7C840,171,960,213,1080,218.7C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
          </svg>
        </div>

        <div className="container relative">
          <div className="grid gap-8 md:grid-cols-2 md:gap-12">
            <motion.div
              className="max-w-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl font-display">
                Secure Your Email, <span className="text-accent-400">Elevate Your Brand</span>
              </h1>
              <p className="mt-6 text-xl text-primary-100">
                Protect your organization against email threats and enhance brand recognition with
                expert-guided email security solutions.
              </p>

              <div className="flex flex-wrap gap-4 mt-8">
                <motion.a
                  href="#features"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="btn btn-accent shadow-sharp-sm"
                >
                  Discover Solutions
                </motion.a>
                <motion.a
                  href="https://brandymail.com/tools?ref=inboxmark"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="btn btn-primary shadow-sharp-sm"
                >
                  Enterprise Tools
                </motion.a>
                <motion.a
                  href="https://inboxmark.com/contact"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="btn btn-secondary shadow-sharp-sm"
                >
                  Get Started
                </motion.a>
              </div>

              <div className="flex items-center mt-8 text-primary-100">
                <ShieldCheckIcon className="w-5 h-5 mr-2" />
                <span>Trusted by leading email security experts</span>
              </div>
            </motion.div>

            <motion.div
              className="relative order-first md:order-last"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              <div className="relative mx-auto w-full max-w-md">
                <div className="overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-secondary-800">
                  <div className="p-1 bg-gradient-to-r from-primary-600 to-accent-600">
                    <div className="flex items-center justify-between px-4 py-2">
                      <div className="flex space-x-1">
                        <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                        <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                        <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                      </div>
                      <span className="text-xs text-white">Inbox Protection</span>
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="flex items-center mb-4">
                      <div className="p-2 mr-4 text-white bg-primary-600 rounded-full">
                        <CheckBadgeIcon className="w-6 h-6" />
                      </div>
                      <div>
                        <h3 className="font-medium text-secondary-900 dark:text-white">
                          Verified Sender
                        </h3>
                        <p className="text-sm text-secondary-500 dark:text-secondary-400">
                          InboxMark Enterprise Solutions
                        </p>
                      </div>
                    </div>

                    <div className="p-4 mb-4 bg-secondary-50 rounded-md dark:bg-secondary-700">
                      <p className="text-sm text-secondary-800 dark:text-secondary-200">
                        DMARC status: <span className="font-semibold text-success">Enforced</span>
                        <br />
                        BIMI status: <span className="font-semibold text-success">Active</span>
                        <br />
                        VMC: <span className="font-semibold text-success">Valid</span>
                      </p>
                    </div>

                    <div className="flex items-center p-3 mt-4 text-sm text-white bg-primary-700 rounded-md">
                      <LockClosedIcon className="w-4 h-4 mr-2" />
                      <span>Your email is protected and verified</span>
                    </div>
                  </div>
                </div>

                {/* Decorative elements */}
                <div className="absolute w-40 h-40 rounded-full bg-accent-400/30 -top-12 -right-12 blur-2xl"></div>
                <div className="absolute w-40 h-40 rounded-full bg-primary-400/30 -bottom-12 -left-12 blur-2xl"></div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <Section id="stats" className="py-16">
        <motion.div
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
          variants={staggerContainer}
        >
          {stats.map((stat) => (
            <Counter key={stat.label} value={stat.value} label={stat.label} icon={stat.icon} />
          ))}
        </motion.div>
      </Section>

      {/* Features Section */}
      <Section
        id="features"
        title="Enterprise Email Security Solutions"
        subtitle="Comprehensive protection strategies for your organization's email infrastructure and brand reputation."
        className="bg-secondary-50 dark:bg-secondary-800"
      >
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {features.map((feature) => (
            <motion.div
              key={feature.name}
              className="p-6 bg-white rounded-lg shadow-lg dark:bg-secondary-900"
              variants={itemFadeIn}
            >
              <div className="flex items-center justify-center w-12 h-12 mb-5 text-white bg-primary-600 rounded-md dark:bg-primary-700">
                <feature.icon className="w-6 h-6" aria-hidden="true" />
              </div>
              <h3 className="mb-3 text-xl font-semibold text-secondary-900 dark:text-white">
                {feature.name}
              </h3>
              <p className="text-secondary-600 dark:text-secondary-300">{feature.description}</p>

              <Link
                to={`/blog?category=${feature.name.toLowerCase().replace(/ /g, '-')}`}
                className="inline-flex items-center mt-4 text-sm font-medium text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300"
              >
                Learn more
                <ArrowRightIcon className="w-4 h-4 ml-1" />
              </Link>
            </motion.div>
          ))}
        </div>
      </Section>

      {/* CTA Section */}
      <Section
        dark
        className="relative overflow-hidden bg-gradient-to-br from-primary-800 to-primary-900"
      >
        <div className="relative">
          <div className="grid gap-8 lg:grid-cols-2 lg:gap-16">
            <motion.div variants={slideInLeft}>
              <h2 className="text-3xl font-bold tracking-tight text-white md:text-4xl font-display">
                Ready to secure your email communications?
              </h2>
              <p className="mt-4 text-lg text-primary-100">
                Our team of experts will help you implement robust email security measures,
                including DMARC, BIMI, and Verified Mark Certificates.
              </p>

              <div className="flex flex-wrap gap-4 mt-8">
                <motion.a
                  href="https://brandymail.com/?ref=inboxmark-home"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="btn btn-accent shadow-sharp-sm"
                >
                  Enterprise Solutions
                </motion.a>
                <motion.a
                  href="https://inboxmark.com/contact"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="btn btn-secondary shadow-sharp-sm"
                >
                  Get Started
                </motion.a>
                <motion.a
                  href="/blog"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="btn btn-secondary shadow-sharp-sm"
                >
                  Explore Resources
                </motion.a>
              </div>

              <div className="mt-6 pt-6 border-t border-primary-700">
                <a
                  href="https://brandymail.com/why-brandymail?ref=inboxmark-trusted"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-primary-100 hover:text-white transition-colors"
                >
                  <svg
                    className="w-5 h-5 mr-2 text-accent-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    ></path>
                  </svg>
                  <span>
                    Trusted by enterprise organizations worldwide |{' '}
                    <span className="underline">Brandymail Solutions</span>
                  </span>
                </a>
              </div>
            </motion.div>

            <motion.div variants={slideInRight} className="relative">
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 bg-white/10 backdrop-blur-sm rounded-lg">
                  <h3 className="mb-2 text-lg font-semibold text-white">DMARC Implementation</h3>
                  <p className="text-primary-100">
                    Protect against spoofing and phishing with proper DMARC setup
                  </p>
                </div>
                <div className="p-4 bg-white/10 backdrop-blur-sm rounded-lg">
                  <h3 className="mb-2 text-lg font-semibold text-white">BIMI Deployment</h3>
                  <p className="text-primary-100">
                    Display your logo in email clients to build brand recognition
                  </p>
                </div>
                <div className="p-4 bg-white/10 backdrop-blur-sm rounded-lg">
                  <h3 className="mb-2 text-lg font-semibold text-white">VMC Certification</h3>
                  <p className="text-primary-100">
                    Obtain Verified Mark Certificates for maximum trust
                  </p>
                </div>
                <div className="p-4 bg-white/10 backdrop-blur-sm rounded-lg">
                  <h3 className="mb-2 text-lg font-semibold text-white">Phishing Prevention</h3>
                  <p className="text-primary-100">
                    Advanced protection against sophisticated email attacks
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
          <div className="absolute w-64 h-64 bg-primary-600/20 rounded-full blur-3xl -top-10 -right-10"></div>
          <div className="absolute w-64 h-64 bg-accent-600/20 rounded-full blur-3xl -bottom-10 -left-10"></div>
        </div>
      </Section>

      {/* Latest Blog Posts */}
      <Section
        title="Latest Insights"
        subtitle="Expert articles on email security, brand protection, and phishing prevention."
      >
        {isLoading ? (
          <LoadingSpinner size="lg" className="py-12" />
        ) : (
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {posts.map((post) => (
              <motion.div key={post.id} variants={itemFadeIn}>
                <PostCard post={post} />
              </motion.div>
            ))}
          </div>
        )}

        <div className="mt-12 text-center">
          <Link
            to="/blog"
            className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-primary-600 rounded-md shadow-lg hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            View All Articles
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </Section>
    </TransitionWrapper>
  );
}
